// assets
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { NumberFormat } from '../../services/srvc-utilities'

import Slider from "react-slick";

import WebbDividerSmall from '../webx/webb-divider-sm'
import WebbDividerMedium from '../webx/webb-divider-md'

import NavsButtonBack from '../webx/navs-button-back'
import NavsButtonNext from '../webx/navs-button-next'

import { GetUserForm } from '../../services/srvc-utilities'
import { GetLocalUser, GetLocalBusiness } from '../../services/srvc-auth-local'

import { TransfersAssetListUser } from "../../services/srvc-transfers-assets-realm";
import { TokenListUserDomain } from '../../services/srvc-tokens-realm';
import { CredentialsListUserDomain } from '../../services/srvc-credentials-realm';

import listStatus from '../../data.static/data-transfers-status.json'

export default function AssetListUserTravelModule (props) {
  // console.log(props.search)

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx

  const navigate = useNavigate()
  const {id} = useParams()

  const [loader, setLoader] = useState(true)
  const [data, setData] = useState([])
  const [list, setList] = useState(listStatus.data)

  const [search, setSearch] = useState()

  const [index, setIndex] = useState(1)
  const [items, setItems] = useState(10)

  const [curr, setCurrentIndex] = useState(1)
  const [next, setNextIndex] = useState()
  const [last, setLastIndex] = useState()

  const [count, setCount] = useState()
  const [total, setTotal] = useState()

  const [text, setText] = useState('')

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "24px",    
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    swipeToSlide: true,
    // afterChange: function(index) {
    //   console.log(
    //     `Slider Changed to: ${index + 1}, background: #222; color: #bada55`
    //   );
    // } 
  };

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true)

        const result = await CredentialsListUserDomain({
          data: {
            user: asset.item,
            domain: 'travel',
            index: index,
            items: items,
            filters: { name: props.search }
          }
        })
       // console.log('r', result)

        if (result.stat) {
          // && resAssets.stat
          setData(result.data.list)

          const resTotal = result.data.count //+resAssets.data.count
          setTotal(result.data.count)

          setText(
            `${(index - 1) * items + 1} - ${
              index * items < resTotal ? index * items : resTotal
            } of ${resTotal}`
          )
        }

        setLoader(false)
      }
      fetchData()
    } else {
    }
  }, [props.search, index, items])

  const NextIndex = async () => {
    if (data.length < items) {
    } else {
      setNextIndex(curr + 1)
      setIndex(curr + 1)
      setCurrentIndex(curr + 1)
    }
  }

  const LastIndex = async () => {
    if (index == 1) {
    } else {
      setLastIndex(curr - 1)
      setIndex(curr - 1)
      setCurrentIndex(curr - 1)
    }
  }

  const handleClick = (item) => {
    navigate(`/${asset.role}/assets/${item}`)
  }

  // console.log(data)

  if (loader)
    return (
      <>
        <div
          className='p-3 back-color-wite rounded-xd border align-middle'
          style={{}}
        >
          <span className='align-middle text-lead'>
            <i className='bx bxs-info-circle text-color-wait'></i>
          </span>
          <span className='ms-1 text-color-tone'>Please Wait...</span>
        </div>
      </>
    )

  if (!loader && (!data || data.length === 0))
    return (
      <>
        <div
          className='p-3 back-color-wite rounded-xd border align-middle'
          style={{}}
        >
          <span className='align-middle text-lead'>
            <i className='bx bxs-info-circle text-color-tint'></i>
          </span>
          <span className='ms-1 text-color-tone'>No Digital Assets</span>
        </div>
      </>
    )

  return (
    <>
      {/* data-list */}
      <div className='d-flex mx-3 mb-2 text-small d-none'>
        <div className=''>Showing 1-5 of {total}</div>
        <div className='ms-auto text-end'></div>
      </div>

      <div className='back-color-wite rounded-xd p-2'>
      {data && data.map((item, i) => (
        <div className='' key={i}>

          <div className='d-flex p-1 rounded-xd cursor' onClick={() => handleClick(item.item)}>
            <div className='' style={{width: '4.6rem', height: '4.6rem'}}>
              <div className='media-cube'>
                <img src={item.media.link} className='rounded-xd' alt="" ></img>
              </div>
            </div>

            <div className='px-1 ms-2'>
              <p className='text-bold m-0'>{item.meta.name || '******'}</p>
              <p className='text-small m-0 '>{item.webx.nmbr || '******'}</p>
              <p className='text-small m-0 d-none'>From :  {(new Date(parseInt(item?.dates?.start))).toLocaleString() || '******'}</p> 
              <p className='text-small m-0 d-none'>To : {(new Date(parseInt(item?.dates?.end))).toLocaleString() || '******'}</p> 
            </div>

            <div className='ms-auto'>
              <div className={`vr m-2 rounded-xd opacity-75 back-color-${item?.status?.mint && !item?.status?.burn ? 'success' : 'error'}`} 
                style={{width: '.25rem', height: '79%'}}>

              </div>
            </div>
          </div>
          
          <div className={`d-flex p-1 ${i<data.length-1 ? '' : 'd-none'}`}>
            <div className='' style={{width: '0'}}></div>
            <div className='w-100'>
              <div className='border-bottom'></div>
            </div>
          </div>

        </div>
      ))}
      </div>
      
      {/* data-cards */}
      <div className='d-none'>
      <Slider {...settings}>
      {data && data.map((item, i) => (
          <div key={i} className='px-1'>
            <div className='media-standard'>
              <img src={item.media.link} className='rounded-xd' alt=""></img>
              <div className='btn back-color-dark rounded-wd text-mini'
                style={{ backgroundColor: 'rgba(0,0,0,0.75)' }}
              > {item?.unit?.nmbr ||'1234567'}
              </div>
            </div>
            <div className='mt-2 mx-3'>
              <p className='m-0 text-bold text-sm'>{item?.meta?.name || 'BharatID Asset'}</p>
              <p className='m-0 text-small text-sm'>{item?.user?.name || 'BharatID Creator'}</p>
              <p className='m-0 text-small text-sm'>{item?.unit?.nmbr || Date.now().toString()}</p>
            </div>
          </div>
        ))}
      </Slider>
      </div>

    </>
  )
}
