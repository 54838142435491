// assets
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { NumberFormat } from '../../services/srvc-utilities'

import Lottie from 'react-lottie';
import medialottie from '../../data.media/alerts-success.json'

import WebbDividerSmall from '../webx/webb-divider-sm'
import WebbDividerMedium from '../webx/webb-divider-md'

import { GetUserForm } from '../../services/srvc-utilities'
import { GetLocalUser, GetLocalBusiness } from '../../services/srvc-auth-local'

import { AssetDetails, AssetUnitDetails} from '../../services/srvc-assets-realm'
import { AssetOfferList, AssetOfferStatusSet } from '../../services/srvc-assets-discover-realm'

import { AccountsBaseBalance } from "../../services/srvc-accounts-realm";
import { TransferFundCreate, TransferFundSubmit, TransfersFundAccountCredit, TransfersFundCreate, TransfersFundSubmit } from '../../services/srvc-transfers-funds-realm'

// user creds
import { CredentialsListUserDomain } from '../../services/srvc-credentials-realm';


import { AccountsMinterDetails } from '../../services/srvc-accounts-minter-realm'
import { AccountsMinterStatus, AccountsMinterInit } from '../../services/srvc-accounts-minter-realm'
import { TextReverse } from '../../services/srvc-encr-node'



import axios from 'axios'
import { TransfersAssetCreate, TransfersAssetListUnit, TransfersAssetListUser, TransfersAssetStatusSet } from '../../services/srvc-transfers-assets-realm';
import { AssetUnitDataBookSet, AssetUnitDataSaleSet } from '../../services/srvc-assets-units-realm';
import { TokenClaim, TokenTransfer } from '../../services/srvc-tokens-rand-realm';

import listActions from '../../data.static/data-discover-actions.json'
import Jazzicon from 'react-jazzicon/dist/Jazzicon';
import NavsButtonBack from '../webx/navs-button-back';
import NavsButtonNext from '../webx/navs-button-next';

import listStatus from '../../data.static/data-transfers-status.json'
import { StatAssetCommentList, StatAssetCommentSave  } from '../../services/srvc-rooms-realm';
import { StatAssetLike ,StatAssetDislike,UserAssetLikeStat} from '../../services/srvc-assets-stats-realm';
import { CancelOffer, EditOffer } from '../../services/srvc-offers-realm';
import FormNeeded from '../webx/form-needed';



export default function DiscoverDetailsModule (props) {
  

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx

  const navigate = useNavigate()
  const {id} = useParams()

  const [loader, setLoader] = useState(true)
  const [submit, setSubmit] = useState(false)
  const [done, setDone] = useState(false)
  const [memo, setMemo] = useState('...')

  const [list, setList] = useState(listStatus.data)
  const [data, setData] = useState()
  const [creds, setCreds] = useState()

  const [index, setIndex] = useState(1)
  const [items, setItems] = useState(10)
  const [curr, setCurrentIndex] = useState(1)
  const [next, setNextIndex] = useState()
  const [last, setLastIndex] = useState()
  const [text, setText] = useState('')
  const [total, setTotal] = useState()
  const [dataUser, setDataUser] = useState([])
  const [curentTime,setCurrentTime] =useState(new Date(Date.now()))
  const [endDate,setEndDate]=useState()

  const [inputComment,setInputComment] =useState("")
  const [Like,setLike] =useState("")
  const [Dislike,setDislike] =useState("")
  const [commentsList,setCommentsList]=useState([])
  const [refresh,setRefresh]=useState(true)
  const [refreshOffers,setRefreshOffers]=useState(true)
  const [itemIdOffer,setItemIdOffer]=useState()
  const [offerRate,setOfferRate]=useState()
  const [myOfferList,setMyOfferList]=useState([])
  const [otherOfferList,setOtherOfferList] =useState([])


  const [refreshCommentList,setRefreshCommentList] =useState(true)
  const [reset,setReset]=useState(true)


  const [rates, setRates] = useState() // creator offers
  const [offers, setOffers] = useState() // user offers

  const [user, setUser] = useState()
  const [creator, setCreator] = useState()

  const [checkout, setCheckout] = useState('')
  const [checkoutitem, setCheckoutItem] = useState()
  const [checkoutstatus, setCheckoutStatus] = useState(false)
  const [checkoutdone, setCheckoutDone] = useState(false)

  const [balancestatus, setBalanceStatus] = useState(false)
  const [balancememo, setBalanceMemo] = useState(false)
  const [balance, setBalance] = useState({number: '0', ticker: ''})
  
  const [member, setMember] = useState(false)
  
  const [modalstatus, setModalStatus] = useState(false);
  const [action, setAction] = useState('')

  const [orderId, setOrderId] = useState('')
  const [order, setOrder] = useState()

  const [commerce, setCommerce] = useState(false)

  const [modalCommerceStatus, setModalCommerceStatus] = useState(false)
  const [modalCancelTransaction, setModalCancelTransaction] = useState(false)
  const [modalCheckoutStatus, setModalCheckoutStatus] = useState(false);

  const [mintstart, setMintStart] = useState(false)
  const [mintdone, setMintDone] = useState(false)
  const [modalMinterStatus, setModalMinterStatus] = useState(false)

  const [minterstatus, setMinterStatus] = useState(false)
  const [minter, setMinter] = useState({number: "", secret: ""})

  const [secret, setSecret] = useState('')
  const [assetTrxnId, setAssetTrxnId] = useState('')

  const [actions, setActions] = useState([]);
  const [clicked,setClicked]=useState("offers")

  


  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: medialottie,
    rendererSettings: { preserveAspectRatio: "xMidYMid slice" }
  };


  const [code, setCode] = useState('')


  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true)

        const result = await AssetUnitDetails({
          data: { item: id, user: asset.item },
          srvc: '******'
        })
        // console.log(result)

        var ratex = []
        if (result.stat) {
          setData(result.data)
          setLike(result.data.webb.like)
          setEndDate(new Date(result.data.dates.end))
          ratex = (Array.from(result?.data?.rates, x => {return {
            ...x, 
            active: x.item == '69c214c90838489b9ecb35383bebe94e6',
            status: false
          }}) || [])

        }
        // console.log(ratex)
        var credx = await CredentialsListUserDomain({
          data: { 
            user: asset.item, domain: 'members', 
            index: 1, items: 99,
            filters: { name: '' }
          }
        })
        if (credx.stat) { setCreds(result.data.list) }

        for (let c=0; c < ratex.length; c++) {
          var index = credx?.data?.list?.findIndex(z => z.unit.item == ratex[c].item)
          if (index > -1) ratex[c] = {...ratex[c], active: true} 
        }
        // console.log(ratex)
        
        setRates(rates => [...ratex.filter(x => x.active)])

        setLoader(false)
      }
      fetchData()
    } else {}
  }, [refreshOffers, refresh])

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true)

        const ratex = await AssetOfferList({
          data: { item: id, user: asset.item },
          srvc: '******'
        })
        // console.log (ratex)
        if (ratex.stat) {
          setLoader(false); 
          setOffers(Array.from(ratex.data.list, x => {return {...x, rate: {number: x.rate.nmbr, ticker: x.rate.tick}}}))
          const allOffers = Array.from(ratex.data.list, x => {
            return {...x, rate: {number: x.rate.nmbr, ticker: x.rate.tick}}
           
        });
        setMyOfferList(allOffers.filter(item => item.user.mail === asset.mail))
        setOtherOfferList( allOffers.filter(item => item.user.mail !== asset.mail))
        }

      }
      fetchData() 
    } else {}
  }, [reset])


  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        const datx = { unit: id, user: asset.item }
        const result = await StatAssetCommentList({
          data: datx, 
        })
        // console.log(result,"Comment")
        if(result){
          setCommentsList(result.data.list)
        }
  
      }
      fetchData()
    } else {
    }
  }, [refreshCommentList])

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true)
  
        const datx = { unit: id ,index: index.toString(), items: items.toString() }
        const result = await TransfersAssetListUser({
          data: datx, 
        })
        // console.log(result)
        if (result.stat) {
          setDataUser(result.data.list)

          const resTotal = result.data.count 
          setTotal(result.data.count)

          setText(
            `${(index - 1) * items + 1} - ${
              index * items < resTotal ? index * items : resTotal
            } of ${resTotal}`
          )
        }

        setLoader(false)
      }
      fetchData()
    } else {
    }
  }, [index, items])
  
  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
  
        const datx = { unit: id, user: asset.item,  }
        const result = await UserAssetLikeStat({
          data: datx, 
        })
        if(result){
          setLike(result?.data?.sort?.like)
          setDislike(result?.data?.sort?.dislike)
        }
      
      }
      fetchData()
    } else {
    }
  }, [refresh])


  useEffect( () => {

    if (asset){
      const fetchData = async() => {
        
        var actionx = Array.from(listActions.data.filter(x => x.actv), z => 
          { return {...z,}}
        )

        actionx = Array.from(actionx, x => { 
          
          return { ...x,  }
        })

        setActions(actionx.filter(x => x.actv))

      }
      fetchData()
    } else {}
  },[refresh]);


  useEffect(() => {
    if (asset) {
      const fetchData = async () => {

        const result = await AccountsBaseBalance({data: {user: asset.item}, srvc: '******'})
        // console.log (result) 

        if (result.stat) { setBalance(result.data.balance) }

      }
      fetchData()
    } else {}
  }, [])

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        if (parseInt(balance?.number/1000000) > checkoutitem?.rate?.number)
          setBalanceStatus(true) 
        else setBalanceStatus(false)

      }
      fetchData()
    } else {}
  }, [checkoutitem]) 
  
  

  const handleUserOfferSelect = async(item) => {

    var ratex = Array.from(rates, x => { return {...x, status: false}})
    var x = ratex.findIndex(z => z.item == item)
    ratex[x] = {...ratex[x], status: true}
    setRates(rates => [...ratex])

    var checkout = ratex.findIndex(z => z.status)
    if (checkout>-1) setCheckoutStatus(true)

  }

  const handleAlternateOfferSelect = async(item) => {

    var ratex = Array.from(offers, x => { return {...x, status: false}})
    var x = ratex.findIndex(z => z.user.item == item)
    ratex[x] = {...ratex[x], status: true}
    setRates(rates => [...ratex])

    var checkout = ratex.findIndex(z => z.status)
    if (checkout>-1) setCheckoutStatus(true)

  }




  const handleCheckoutUserOffer = async() => {
    // console.log(checkout)
    setAction('ecommerce')



    var datx = {
      "credit": {
        "name": asset.name || "******", 
        "mail": asset?.mail || "******",
        "item": asset?.item || "******",
      },
      "debit": {
        "name": asset?.name || "******", 
        "mail": asset?.mail || "******",
        "item": asset?.item || "******",
      },
      "meta": {"name": "account.credit", "memo": `account.credit`},
      "count": {
        "number": rates.find(x=>x.status)?.rate?.number || '0', 
        "ticker": 'INR'
      },
      "rate": {"number": "1", "ticker": ""},
      "asset": {},
      "unit": {},
      
    }
    var res = await TransfersFundAccountCredit({ data: datx, client :'', service: '' })
    //console.log(res)

    // 2 Paymetn Gateway Call
    var res = await handleRazorPayPaymentGateway(res.data.item)
    //console.log(res)

  }

  const handleRazorPayPaymentGateway = async(trxn)=>{
    // console.log(rates.find(x=>x.status)?.rate?.number)
    // console.log(checkoutitem)
    const orderCreate = await axios.post("https://ap-south-1.aws.data.mongodb-api.com/app/bharat-transfers-orders-mbizg/endpoint/order/create", {
      data: {amount: checkoutitem?.rate?.number} // rates.find(x=>x.status)?.rate?.number
    }) 
    // console.log(orderCreate)
    const dataSave= await axios.post("https://ap-south-1.aws.data.mongodb-api.com/app/bharat-transfers-orders-mbizg/endpoint/order/save", {
      data: {...orderCreate.data._rejectionHandler0, stat:0 , trxn:trxn}
    })

    const orderTx = orderCreate.data._rejectionHandler0
    setOrder(orderTx)
    setOrderId(orderTx.id)
    const options = {
    key: process.env.REACT_APP_RAZOR_PAY_KEY, // process.env.RAZORPAY_APP_KEY
    amount: orderTx.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    currency: "INR",
    name:    data?.creator?.name,         // checkoutitem?.feat?.mode == "resale" ? checkoutitem.user.name :  data?.creator?.name ,
    description: `${data?.meta?.name} sale`,
    image: "https://example.com/your_logo",
    order_id: orderTx.id, 
    handler: (res) => {
      // console.log(res)
      paymentVerificationSave(res)
      return res
    },
    prefill: {
        name: asset.name,
        email: asset.mail,
        contact: "9000090000"
    },
    notes: {
        address: "Razorpay Corporate Office"
    },
    theme: {
        color: "#121212"
    }
    };
    // console.log(options)
    const razor = new window.Razorpay(options);
    razor.open()

  }

  const paymentVerificationSave = async(value) => {
    const res = await axios.post("https://ap-south-1.aws.data.mongodb-api.com/app/bharat-transfers-orders-mbizg/endpoint/payment/verify", {data:value})
    if(res.data.stat){
      setCommerce(true)

      setTimeout(()=>{
      modalClose()
        // console.log(rates.find(x=>x.status))
        // console.log(offers)
        if(checkoutitem?.feat?.mode == "resale" )
        {
          handleFundTransferUserToSeller() // resale
        }
        else{
          handleFundTransferUserToCreator()
        }
        modalCheckoutClose()
      },3000)
    
    }
  }

  const modalTransactionClose = async () =>{
    const res = await axios.post("https://ap-south-1.aws.data.mongodb-api.com/app/bharat-transfers-orders-mbizg/endpoint/order/cancel", {data:{id:orderId}})
    if(res.data.stat)
    {
      modalCheckoutClose()
      setModalCancelTransaction(false)
      window.location.reload(true)
    }

  }

  const modalTransactionOpen = () =>{
    setModalCancelTransaction(true)
  }

  const modalCheckoutClose = () => {
    setModalCommerceStatus(false)
    setCommerce(false)
    setModalCheckoutStatus(false);
  };


  const ContinueOrderPurchase = async() =>{

    const options = {
      key: process.env.REACT_APP_RAZOR_PAY_KEY,
      amount: order.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: "INR",
      name: data?.creator?.name,
      description: "account.credit",
      image: "https://example.com/your_logo",
      order_id: order.id, 
      handler: (res) => {
        // console.log(res)
        paymentVerificationSave(res)
        return res
      },
      prefill: {
          name: asset.name,
          email: asset.mail,
          contact: "9000090000"
      },
      notes: {
          address: "Razorpay Corporate Office"
      },
      theme: {
          color: "#121212"
      }
      };
      const razor = new window.Razorpay(options);
      razor.open()
    

 }

 const handleFundTransferUserToCreator = async()=>{
  var datx = {
    "credit": {
      "name": data?.creator?.name || "******", 
      "mail": data?.creator?.mail || "******",
      "item": data?.creator?.item || "******",
    },
    "debit": {
      "name": asset?.name || "******", 
      "mail": asset?.mail || "******",
      "item": asset?.item || "******",
    },
    "meta": {"name": `${data?.meta?.name || 'sale'}`, "memo": `${data?.meta?.name || 'ticket'} sale` },
    "count": {"number": "1", "ticker": ""},
    "rate": {
      "number": rates.find(x=>x.status)?.rate?.number || '0', 
      "ticker": 'INR'
    },
    "asset": data.asset || {},
    "unit": data.unit || {}
  }
  var res = await TransfersFundCreate({ 
    data: datx, 
    client:  '', 
    service:  '' 
  })
  // console.log(res)
  
  // 6: fund transfer submit
  datx = {
    "user": asset?.item || "******",
    "item": res?.data?.item || "******",
  }
  res = await TransfersFundSubmit({ data: datx, client :'', service: '' })
  // console.log(res)

  if(res.stat) {
    setCommerce(true)
    handleAssetTransferCreate()
    setTimeout(()=>{
      setModalCommerceStatus(false)
      // setReset(true)

      // modalCommerceClose()

    },3000)
    setModalMinterStatus(true)
  }
}

const handleFundTransferUserToSeller = async()=>{

 
  var datx = {
    "credit": {
      "name": checkoutitem?.user?.name|| "******", 
      "mail": checkoutitem?.user?.mail || "******",
      "item": checkoutitem?.user?.item || "******",
    },
    "debit": {
      "name": asset?.name || "******", 
      "mail": asset?.mail || "******",
      "item": asset?.item || "******",
    },
    "meta": {"name": `${data?.meta?.name || 'sale'}`, "memo": `${data?.meta?.name || 'ticket'} sale` },
    "count": {"number": "1", "ticker": ""},
    "rate": {
      "number": rates.find(x=>x.status)?.rate?.number || '0', 
      "ticker": 'INR'
    },
    "asset": data.asset || {},
    "unit": data.unit || {}
  }
  var res = await TransfersFundCreate({ 
    data: datx, 
    client:  '', 
    service:  '' 
  })
  // console.log(res)
  
  // 6: fund transfer submit
  datx = {
    "user": asset?.item || "******",
    "item": res?.data?.item || "******",
  }
  res = await TransfersFundSubmit({ data: datx, client :'', service: '' })
  // console.log(res)
  var res = await AssetOfferStatusSet({data:{item: checkoutitem.item, active: false}, client:'', service:'' })

  // now split 10% from seller to creator 
  var datx = {
    "credit": {
      "name": data?.creator?.name || "******", 
      "mail": data?.creator?.mail || "******",
      "item": data?.creator?.item || "******",
    },
    "debit": {
      "name": checkoutitem?.user?.name|| "******", 
      "mail": checkoutitem?.user?.mail || "******",
      "item": checkoutitem?.user?.item || "******",
    },
    "meta": {"name": `${data?.meta?.name + 'resale'|| 'sale'}`, "memo": `${data?.meta?.name || 'ticket'} commission to creator` },
    "count": {"number": "1", "ticker": ""},
    "rate": {
      "number": ((parseFloat(rates.find(x=>x.status)?.rate?.number) *10)/100 ).toString() || '0', 
      "ticker": 'INR'
    },
    "asset": data.asset || {},
    "unit": data.unit || {}
  }
  var res = await TransfersFundCreate({ 
    data: datx, 
    client:  '', 
    service:  '' 
  })
  console.log(res)
  
  // 6: fund transfer submit
  datx = {
    "user": asset?.item || "******",
    "item": res?.data?.item || "******",
  }
  res = await TransfersFundSubmit({ data: datx, client :'', service: '' })
  console.log(res)
  if(res.stat) {
    setCommerce(true)
    handleAssetTransferCreate()
    setTimeout(()=>{
      setModalCommerceStatus(false)
      
      // setReset(true)

      // modalCommerceClose()

    },3000)
    setModalMinterStatus(true)
  }
}

const handleClick = (item) => {
if(item.code !="likes" && item.code != "dislikes"){
  setClicked(item.code)
}

  if(item.code=="likes"){
    handleLikeClick()
  }

  if(item.code=="dislikes"){
   handleDisLikeClick()
  }

};


const handleAssetTransferCreate = async() =>{
  //asset transfer create
  var debit= {
      "name": data.creator?.name || "******", 
      "mail": data.creator?.mail || "******",
      "item": data.creator?.item || "******",
    }
  
  if(checkoutitem?.feat?.mode == "resale" ){
    debit = {
      "name": checkoutitem?.user?.name|| "******", 
      "mail": checkoutitem?.user?.mail || "******",
      "item": checkoutitem?.user?.item || "******",
    }
  }

  const offerx = rates.find(x => x.status)
  var datx = {
    "credit": {
      "name": asset?.name || "******", 
      "mail": asset?.mail || "******",
      "item": asset?.item || "******",
    },
    "debit": debit,
    "meta": {"name": `${data?.meta?.name || 'sale'}`, "memo": `${data?.meta?.name || 'ticket'} sale` },
    "count": {"number": "1", "ticker": "IAM"},
    "rate": {
      "number": offerx?.rate?.number || '0', 
      "ticker": offerx?.rate?.ticker || '******'
    },
    "asset": data.asset.item || '',
    "unit": data.item || '',
    "mode": checkoutitem?.feat?.mode == "resale" ? 'resale': 'sale',
    "crxx": checkoutitem?.cred ? checkoutitem.cred : null
  }
  // console.log(datx)
  

  var result = await TransfersAssetCreate({ 
    data: datx, 
    client:  '', 
    service:  '' 
  })
  // console.log(result)
  if(result.stat)
  {
    //console.log(result.data.item)
    setAssetTrxnId(result.data.item)
  }

  if (!result.stat) {
    setMemo('Failed: Asset Transfer Not Created')
    return
  } 

}


const handleUnitTransfer = async() => {

  setMintStart(true)
  setMemo('Please Wait... Mint Asset')

  // 0: create asset transfer
  // 1: check minter account
  // 2: if no minter account, create and mint, send alert
  // 3: if minter account, get + check account details
  // 4: check minter account status - balance
  // 5: initiate transfer
  // 6: set asset transfer status
  setMinterStatus(true)
    

  setMemo('Please Wait... 1/5')

  var datx = {user: asset?.item}
  const minterx = await AccountsMinterDetails({ data: datx, client: '', service: '' })
  // console.log(minterx)

  // if (minterx.data.account.code !== secret) {
  //   setMemo('Failed: Incorrect Account Passcode')
  //   setMinterStatus(false)
  //     return
  //   } 
    // else setMinterStatus(true)

  setMinterStatus(true)
  const mnemonix = await TextReverse( {text: minterx.data.account.secret, secret: minterx.data.account.code})
  
 
  
  setMemo('Success: Asset Transfer Created')
  setMemo('Please Wait... 2/5')
  var bookx = await AssetUnitDataBookSet({ data: {unit: data.item, count: '1', mode: 'credit' }, srvc: '******' })
  
  datx = {
    "user": asset?.item || "******"
  }
  setMemo('Please Wait... 3/5')
  var statusx = await AccountsMinterStatus({ data: datx, client: '', service: ''})
  // console.log(statusx)
  if (!statusx?.data?.status?.mint) {
    // console.log('Please Wait... 3x')
    var initx = await AccountsMinterInit({ data: datx, client: '', service: ''})
    // console.log(initx)
    statusx = await AccountsMinterStatus({ data: datx, client: '', service: ''})
  }

  // console.log(minterx.data.account)
  if (statusx?.data?.status?.mint) {
    setMemo('Please Wait... 4/5')
    datx = {
      "account": {"number": minterx?.data?.account?.number, "secret": mnemonix.data},
      "number": data?.webx?.number, "asset": data?.webx?.item, "count": "1", 
      "memo": "", "chain": "416001"
    }
    const claimx = await TokenClaim({ data: datx, client: '', service: ''})
    // console.log (claimx)
    datx = {
      "debit": {"number": "", "secret": ""},
      "credit": {"number": minterx?.data?.account?.number, "secret": mnemonix.data},
      "number": data?.webx?.number, "asset": data?.webx?.item, "count": "1", 
      "memo": "", "chain": "416001"
    }
    const transferx = await TokenTransfer({ data: datx, client: '', service: ''})
    // console.log (transferx)

    if (!transferx.stat) 
    return

    var salex = await AssetUnitDataSaleSet({ data: {unit: data.item, count: '1', mode: 'credit' }, srvc: '******' })
    // console.log(salex)
    setMemo('Please Wait... 5/5')
    var statusSet = await TransfersAssetStatusSet({data:{item: assetTrxnId, status:"6"}, client: '', service:''})
    setMemo('Success: Asset Minted')
    
    if (transferx.stat) {
      if(checkoutitem.meta.name == "General" ){}
      if(checkoutitem.meta.name != "General" ){
        // sent offer actv to false
        // var res = await AssetOfferStatusSet({data:{item: checkoutitem.item, active: false}, client:'', service:'' })
        // console.log(res)
      }
      setMintDone(true)
      setTimeout(()=>{ 
        setModalMinterStatus(false) 
        modalMinterClose()
        navigate("/user/assets")
      },3000)
    
      setModalCheckoutStatus(false)

      // props.done(true) 

    }

  }

} 


const modalMinterShow = ()=>{
  setModalMinterStatus(true)
}

const handleLikeClick = async ()=>{
  const datx ={
    asset:data.asset.item,
    user:asset.item,
    unit:id
  }
  const result = await StatAssetLike({ data: datx, })
  // setLike(like=> like+1)
  setRefresh(!refresh)
}

const handleDisLikeClick = async ()=>{
  const datx ={
    asset:data.asset.item,
    user:asset.item,
    unit:id
  }
  const result = await StatAssetDislike({ data: datx, })
  // setLike(like=> like-1)
  setRefresh(!refresh)
  }

const handleCommentSave = async ()=>{
  const datx ={
    asset:data.asset.item,
    user:asset.item,
    unit:id,
    text:inputComment
  }
  setInputComment(" ")
  if(inputComment){
    const result = await StatAssetCommentSave({ data: datx, })
    // console.log(result)
    setRefreshCommentList(!refreshCommentList)
  }
}

const handleCommentChange = async (value)=>{
  setInputComment(value)
}


const modalMinterClose = ()=>{
  setModalMinterStatus(false)
}

const handleCancelOffer =async (item)=>{
  const datx={
    item:item
  }
  const result = await CancelOffer({ data: datx, })
  setReset(!reset)
}
const handleChangeRate =async (item)=>{
  const datx={
    item:item,
    rate:offerRate
  }
  const result = await EditOffer({ data: datx, })
  setReset(!reset)
}


  const handleCheckout = async() => {
    // fund transfer


    // asset.unit.data


    // asset.claim status


    // asset.transfer


    // moda/.close
    setLoader(false)
    setTimeout(() => { modalClose() },3000)

  }

  const modalShow = (item) => { 
    // item.rate.number=0
    if(item.rate.number == "0")
    {
      handleAssetTransferCreate()
      setModalMinterStatus(true)
    }
    else{
      setModalStatus(true); 
    }
   
    // 
  };
  const modalClose = () => { setModalStatus(false) ;setRefreshOffers(!refreshOffers) }; 

  const NextIndex = async () => {
    if (dataUser.length < items) {
    } else {
      setNextIndex(curr + 1)
      setIndex(curr + 1)
      setCurrentIndex(curr + 1)
    }
  }

  const LastIndex = async () => {
    if (index == 1) {
    } else {
      setLastIndex(curr - 1)
      setIndex(curr - 1)
      setCurrentIndex(curr - 1)
    }
  }

  
  
  if (loader)
  return (
    <>
      <div
        className='p-3 back-color-wite rounded-xd border align-middle'
        style={{}}
      >
        <span className='align-middle text-lead'>
          <i className='bx bxs-info-circle text-color-wait'></i>
        </span>
        <span className='ms-1 text-color-tone'>Please Wait...</span>
      </div>
    </>
  )

  //console.log(endDate)
  return (
    <>
      
      {/* data */}
      <div className='p-1 back-color-wite rounded-xd'>
        <div className='media-standard rounded-xd'>
          <img className='w-100 rounded-xd shadow' src = {data?.media?.link} alt="..."></img>
        </div>
        
        <div className='mx-3'>
          <WebbDividerSmall />
          <p className='text-normal text-bold m-0 text-truncate'>{data?.meta?.name}</p>
          <p className='text-small m-0 mb-2'>{data?.number}</p>
        
          <p className='m-0 text-wd'>{data?.meta?.memo}</p>
        </div>

        <WebbDividerSmall />
        <div className='mx-3'>
          <span className='text-small m-0'>{data?.creator?.name}</span>
        </div>
        
        <WebbDividerSmall />
        <div className='mx-3 d-flex gap-4'>
          <div className='d-flex gap-2 align-items-center justify content-center'>
          <p className='m-0 fw-bold'>{data?.webb?.like || 0} </p>
          <span className='text-small m-0 cursor' onClick={()=>{ handleLikeClick()}}> {Like?<i class='bx bxs-like bx-sm' style={{color:'#5486fa'}}  ></i>:<i class='bx bx-like bx-sm' ></i>}</span>
          </div>
          {/* <div>
          <span className='text-small m-0 cursor' onClick={()=>{handleDisLikeClick()}}>{Dislike?<i class='bx bxs-dislike bx-sm' style={{color:'#d54e47'}}  ></i>:<i class='bx bx-dislike bx-sm' ></i>}</span>
          </div> */}
          <div className='d-flex gap-2 align-items-center justify content-center'>
          <p className='m-0 fw-bold'>{data?.webb?.view || 0} </p>

          <span className='text-small m-0 cursor' ><i class='bx bx-show-alt bx-sm'></i></span>
          </div>
        </div>

      </div>

      {/* actions */}
      <WebbDividerSmall />

      <div className="rounded-xd back-color-wite p-2">
      <div className="mb-2"></div>
      <div className={`row row-cols-${actions.length} g-1`}>
      {actions && actions.map((item, i) => (
        <div className="col text-center" key={i}   onClick={() => handleClick(item)}
        >
          <div className="d-flex justify-content-center">
            <div className=""   
            >
              <i className={`${item.icon} text-icon-sm   p-2 rounded-xx   ${item.live ? 'cursor hidark' : 'cursor text-color-tone'} ${item.code==clicked && item.live ? "back-color-next text-color-wite":"back-color-lite"}`} 
              style={{width: '3rem', height: '3rem' }}></i>
            </div>
          </div>
          <div className="mt-1">
            <p className={`text-mini text-uppercase text-truncate m-0 ${item.live ? '' : 'text-color-tone'}`}>{item.name}</p>
          </div>

        </div>
      ))}
      </div>
      </div>
  
      {/* rates */}
      <WebbDividerMedium />
      { endDate == "Invalid Date" || curentTime<=endDate ?
      <>
      <div className={clicked == "offers" ? "" : "d-none"}>
        <p className='text-bold m-0 mx-3 mb-2'>Available Offer(s)</p>
        <div className={rates?.length > 0 ? 'back-color-wite border rounded-xd p-1' : 'd-none'}>
          {rates && rates?.map((item, x) => (item.active ?
          <div className='' key={x} >
            <div className='d-flex m-0 p-2 rounded-wd cursor hidark' 
              onClick={()=>{setAction('checkout'); setCheckoutItem(item); modalShow(item); handleUserOfferSelect(item.user) }}
              
            >
              <div className={` ${item.status ? 'text-color-success' : 'text-color-tint'}`}>
                <span className='text-normal align-middle d-none'>
                  <i className={`${item.status ? 'bx bxs-check-circle' : 'bx bxs-circle'}`}></i>
                </span>
              </div>
              <div className=''>
                <p className='m-0'>{item?.meta?.name || '******'}</p>
                <p className='m-0 text-small d-none'>{item?.number || '******'}</p>
              </div>
              <div className='ms-auto text-end'>
                <span className=''>{item?.rate?.number || '******'}</span>
                <span className='ms-1'></span>
                <span className='text-small text-uppercase'>{item?.rate?.ticker || '******'}</span>
              </div>
            </div>
            <div className={x < rates.length-1 ? 'border-bottom border-light mx-2': ''}></div>
          </div>
          :''))}

        </div>

      </div>

      {/* rates - others */}
      <div className={(offers?.length > 0 && clicked == "offers" ? '' : 'd-none') }>
        <WebbDividerSmall />


        <p className='m-0 mx-3 mb-2'>Other Offer(s): {otherOfferList?.length || 0}</p>
        <div className={otherOfferList?.length > 0  ? 'back-color-wite border rounded-xd p-1' : 'd-none'}>
        {rates && otherOfferList?.map((item, x) => (item.active ?
          <div className='m-0  ' key={x} >
          <div className='d-flex  p-2 m-0 rounded-wd cursor hidark' 
            onClick={()=>{setAction('checkout'); setCheckoutItem(item); modalShow(item); handleAlternateOfferSelect(item.user.item) }}
          >
            <div className={`d-none ${item.status ? 'text-color-success' : 'text-color-tint'}`}>
              <span className='text-normal align-middle'>
                <i className={`${item.status ? 'bx bxs-check-circle' : 'bx bxs-circle'}`}></i>
              </span>
            </div>
            <div className=''>
              <p className='m-0'>{item?.meta?.name || '******'}</p> 
              <p className='m-0 text-small d-none'>{item?.number || '******'}</p>
            </div>
            <div className='ms-auto text-end'>
              <span className=''>{item?.rate?.number || '******'}</span>
              <span className='ms-1'></span>
              <span className='text-small text-uppercase'>{item?.rate?.ticker || '******'}</span>
            </div>
          </div>
          <div className={x < rates.length-1 ? 'border-bottom border-light mx-2': ''}></div>
        </div>
         :""))}
          {/* offers - alt */}

        </div>  
 
         
        <p className='m-0 mx-3 mb-2 mt-4'>My Offer(s): {myOfferList?.length || 0}</p>
        <div className={myOfferList?.length > 0  ? 'back-color-wite border rounded-xd p-1 mb-3' : 'd-none'}>
          {rates && myOfferList?.map((item, x) => (item.active ?
          <div className='m-0  ' key={x} >
            <div className='d-flex p-2 m-0 rounded-wd cursor hidark' 
            data-bs-toggle="modal"
            data-bs-target={`#modEditOffer`}
            onClick={()=>{setItemIdOffer(item.item);setOfferRate(item?.rate?.number)}}
            >
              <div className={`d-none ${item.status ? 'text-color-success' : 'text-color-tint'}`}>
                <span className='text-normal align-middle'>
                  <i className={`${item.status ? 'bx bxs-check-circle' : 'bx bxs-circle'}`}></i>
                </span>
              </div>
              <div className=''>
                <p className='m-0'>{item?.meta?.name || '******'}</p> 
                <p className='m-0 text-small d-none'>{item?.number || '******'}</p>
              </div>
              <div className='ms-auto text-end'>
                <span className=''>{item?.rate?.number || '******'}</span>
                <span className='ms-1'></span>
                <span className='text-small text-uppercase'>{item?.rate?.ticker || '******'}</span>
              </div>
            </div>
            <div className={x < rates.length-1 ? 'border-bottom border-light mx-2': ''}></div>
          </div>  
          : 
          ""))}
          </div>

           
      
      </div>
      </>
      :
      <div className={clicked == "offers" ? "p-3 back-color-wite rounded-xd border align-middle" : "d-none"} style={{}}>  
     <span className="align-middle text-lead">
     <i class='bx bx-info-circle' style={{color:'#e91919'}}  ></i>     </span>
     <span className='ms-1 text-danger'>Event expired...</span>
     </div>
     }

      
      {/* Users */}
      <div className={clicked == "users" && dataUser.length > 0 ? "" : "d-none"}>
      <div className='back-color-wite border rounded'>
      {/* header */}
      <div className="border-bottom text-small text-bold">
        <div className="d-flex p-2 px-3">

          <div className="" style={{width: '5%'}}>         
            <p className="m-0">
              <span className="text-small">
                <i className="bx bxs-grid-alt text-color-tint"></i>
              </span>
            </p>
          </div>  

          <div className="" style={{minWidth: '55%'}}>
            <p className="m-0">
              <span className="">{'User'}</span>
            </p>
          </div>    

          <div className="" style={{width: '20%'}}>         
            <p className="m-0 text-sm">
              <span className="">{'Status'}</span>
            </p>
          </div>  

          <div className="ms-auto text-end text-sm" style={{width: '20%'}}>         
            <p className="m-0 text-sm ">
              <span className="d-none d-md-block">{'Amount'}</span>
              <span className="d-md-none">{'Status'}</span>
            </p>
          </div>

      </div>
      </div>

      {/* data */}
      {dataUser && dataUser.map((item, i) => (
      <div key={i}>
        <div className="d-flex p-2 px-3">
        
          <div className="" style={{width: '5%'}}>         
            <p className="m-0">
              <span className="text">
                <i className="bx bxs-image text-color-tint"></i>
              </span>
            </p>
          </div>  

          <div className="" style={{width: '55%'}}>
            <p className="m-0">
              <span className="text-bold">{item.user.name}</span>
            </p>
            <p className="m-0 text-small d-none">{item?.meta?.memo || '******'}</p>
            <p className="m-0 text-small">
              <span>{(new Date(parseInt(item?.created))).toLocaleString() || '******'}</span>
            </p>
          </div>
          <div className="" style={{width: '20%'}}>
            <span className={`p-1 rounded text-mini text-uppercase text-color-wite back-${list?.find(x => x.code == item.status).color}`}>
              {list?.find(x => x.code == item.status).memo}
            </span>
          </div>
          <div className="ms-auto text-end" style={{width: '20%'}}>
            <p className="m-0">
              <span className="ms-1">{item.mode =='credit' ? '+' : '-'}</span>
              <span className="">{NumberFormat((item?.size?.number || '0'))}</span>
              <span className="ms-1 d-none"></span>
              <span className="text-mini d-none">{item.size.ticker || ''}</span>
            </p>
          </div>
        </div>
        <div className={i < dataUser?.length-1 ? 'border-bottom': ''}></div>
      </div>
      ))}

      </div>

      {/* navs */}
      <WebbDividerSmall />
      <div className={dataUser.length < items ? '' : ''}>
        <div className='d-flex justify-content-between'>
          <div className='' onClick={() => LastIndex()}>
            <NavsButtonBack />
          </div>

          <div className=''>
            <p className='mt-2 text-small'>{text}</p>
          </div>

          <div className='' onClick={() => NextIndex()}>
            <NavsButtonNext />
          </div>
        </div>
      </div>
      </div>
      <div className={clicked == "users" && dataUser.length == 0 ? "" : "d-none"}>
      <div className= 'back-color-wite border rounded-xd p-1'>
        <p className='m-0 p-2'>No Users found...</p>
      </div>
      </div>


      {/* Comments */}
      <div className={clicked == "comments" ? "" : "d-none"}>
        <div className="border rounded-xd justify-content-between  border-none mb-2">
            <div >
                <div className="d-flex flex-column  justify-content-between rounded-xd bg-white p-4 " style={{height:"400px"}}>
                  <div>
                <p>Top Comments</p>
                <div style={{maxHeight:"250px",overflowY:"scroll"}}>
                {commentsList && commentsList.map((item, i) => (
                  <div className="d-flex justify-content-between   gap-3 my-2">
                    <div className='d-flex  gap-3'>
                    <div>
                    <Jazzicon />
                    </div>
                    <div>
                      <p
                        className="m-0 text-primary text-small "
                      >
                      {item.user.name}
                      </p>
                      <p className="m-0 text-small ">{item.sort.comment}</p>
                    </div>
                    </div>
                    <div>
                      <p className="p-1 m-0 px-2 d-flex gap-2 text-end" style={{fontSize:"8px"}}>     
                      <span>{(new Date(parseInt(item?.created))).toLocaleTimeString([],
                       { hour: '2-digit', minute: '2-digit', hour12: true }) || '******'}</span>
                      <span>{(new Date(parseInt(item?.created))).toLocaleDateString() || '******'}</span>

                      </p>
                     
                    </div>
                  </div>
                ))}
                </div>
                </div>
                <div>
                <div className="d-flex align-items-center gap-3 my-2">
                    <div>
                    <Jazzicon />
                    </div>
                    <div className='w-100'>
                    <input type="text" className="form-control height-md  w-100"
                     style={{fontSize:'0.9rem', height:'2.7rem'}}
                     value={inputComment}
                     placeholder="Add your comment..."
                     onChange={({ target }) => {handleCommentChange( target.value) }}
                     >
                    </input>                      
                    </div>
                    <i class='bx bx-send bx-md' style={{cursor:"pointer"}}onClick={()=>handleCommentSave()} ></i>
                  </div>
                </div>
                </div>
            </div>
        </div>
      </div>

       {/* Shares */}
       <div className={clicked == "share" ? "" : "d-none"}>
      <p className='text-bold m-0 mx-3 mb-2'>Share </p>
      </div>
    


      {/* actions */}
      <div className='mb-1'></div>
      <div className={modalstatus && action == "checkout" ? '' : 'd-none'}>
        
        <div className="modal-overlay" id="">
          <div className="modal-content rounded-xd">

            {/* modal header */}
            <div className='p-3 m-0 d-none'>
              <div className='header text-bold m-0'></div>
            </div>
            
            {/* modal content */}
            <div className='border-none scrollbar' > {/* style={{overflowY:'scroll', height:'auto'}} */}
              
              <div className="border-none p-3 mx-3 rounded-xd">

                <div className="text-center">
                  <p className='text-normal m-0'>{'Get Offer'}</p>
                  
                  <div className='mb-2'></div>
                  <p className='text-normal text-bold m-0 text-truncate'>{data?.meta?.name || '******'}</p>
                  <p className='text-small m-0 d-none'>{data?.webx?.number || '******'}</p>
                </div>

                <div className=''>
                  <div className='mb-2'></div>
                  <div className='media-banner'>
                    <img src={data?.media?.link} className='w-100 rounded-xd' alt="..."></img>
                  </div>
                </div>

                <div className='text-center'>
                  <WebbDividerSmall/>
                  <p className='m-0'>Order Value</p>
                  <p className='text-bold m-0'>
                    <span className='text-header-sm'>{checkoutitem?.rate?.number || '0'}</span>
                    <span className='ms-1'></span>
                    <span className='text-small text-uppercase'>{checkoutitem?.rate?.ticker || '0'}</span>
                  </p>
                  <p className='m-0 d-none'>{balance.number/1000000 || '0'}</p>
                </div>

                
                <div className={balancestatus ? 'd-none' : 'd-none'}>
                  
                  <label className="form-label text-small">Enter Account Passcode</label>
                  <input 
                    type="text" 
                    className="form-control height-md text-center"
                    style={{fontSize: '0.9rem', height: '2.7rem'}}
                    value={code}
                    onChange={({ target }) => {setCode(target.value); }}
                    disabled={loader || submit}
                    placeholder="123456" 
                  />
                  <WebbDividerMedium />
                </div>
                

                {/* action */}
                <WebbDividerMedium />
                <div className={loader || submit || done ? 'd-none' : ''}>
                  <button className='btn btn-primary btn-sm text-small rounded-xx w-100'
                    onClick={() =>  handleCheckoutUserOffer()}
                    disabled={loader || submit}
                  >Checkout</button>

                  <div className="mb-1"></div>
                  <button className='btn btn-outline-secondary  border btn-sm text-small rounded-xx w-100'
                    onClick={()=>modalClose()}        
                  >Cancel</button>  
                </div>   


                <div className={!loader && done  ? 'text-center' : 'd-none'}>
                  <p className="m-0">success: asset minted</p>

                  <WebbDividerSmall/>
                  <button className='btn btn-outline-secondary border btn-sm text-small rounded-xx w-100'
                    onClick={()=>modalClose()}        
                  >Close</button>  

                </div>

                <WebbDividerSmall/>
              </div>
            </div>   
        
          </div>
        
        </div>
      </div>      

      {/* modal - ecom */}
      <div className={modalstatus && action == "ecommerce" ? '' : 'd-none'}>
        
        <div className="modal-overlay" id="" onClick={()=>modalTransactionOpen()}>
          <div className="modal-content rounded-xd">

            {/* modal header */}
            <div className='p-3 m-0 d-none'>
              <div className='header text-bold m-0'></div>
            </div>
            
            {/* modal content */}
            <div className='border-none scrollbar' > {/* style={{overflowY:'scroll', height:'auto'}} */}
              
              <div className="border-none p-3 mx-3 rounded-xd">

                <div className="text-center">
                  <p className='text-normal m-0'>{'Complete Payment'}</p>
                  
                  <div className='mb-2'></div>
                  <p className='text-normal text-bold m-0 text-truncate'>{data?.meta?.name || '******'}</p>
                  <p className='text-small m-0 d-none'>{data?.webx?.number || '******'}</p>
                </div>

                <div className=''>
                  <div className='mb-2'></div>
                  <div className='media-banner'>
                    <img src={data?.media?.link} className='w-100 rounded-xd' alt="..."></img>
                  </div>
                </div>

                <div className='text-center'>
                  <WebbDividerSmall/>
                  <p className='m-0'>Order Value</p>
                  <p className='text-bold m-0'>
                    <span className='text-header-sm'>{checkoutitem?.rate?.number || '0'}</span>
                    <span className='ms-1'></span>
                    <span className='text-small text-uppercase'>{checkoutitem?.rate?.ticker || '0'}</span>
                  </p>
                  <p className='m-0 d-none'>{balance.number/1000000 || '0'}</p>
                </div>

                {/* action */}
                <WebbDividerMedium />
                <div className='text-center'>
                  <p className='m-0'>Please Wait...</p>
                </div>

                {/* success */}
                <div className={`mb-3 text-center ${commerce ? '' : 'd-none'}`}>
                    <Lottie 
                      options={defaultOptions}
                      height={200}
                      width={200}
                    />
                  </div>
                <WebbDividerMedium />
                <WebbDividerSmall/>

              </div>
            </div>   
        
          </div>
        
        </div>
      </div> 

       {/* modal - ecom - cancel */}
      <div className={`container ${modalCancelTransaction ? '' : 'd-none'}`}>
        <div className='row'>
          <div className='col'>
            <div className="modal-overlay" >
              <div className="modal-content rounded-xd" onClick={(e) => e.stopPropagation()}>
                        
                {/* modal header */}
                
                        
                {/* modal content */}
                <div className={`modal-body border-none p-3`}>
                  <div className='text-center'>
                    <p className='text-normal m-0'>{'Cancel Transaction'}</p>
                  </div>
                  <p className='text-center mt-3'>Are You Sure to Cancel Transaction</p>
                  <div className='mt-5'>
                      <div className='d-flex'>
                          <div className='me-auto'><button onClick={ ()=> modalTransactionClose() } className='btn btn-outline-danger rounded-xx'>Yes</button></div>
                          <div className='text-end'><button className='btn btn-outline-success rounded-xx' onClick={()=>{ContinueOrderPurchase();}}>No</button></div>
                      </div>
                  </div>
                
                  <WebbDividerSmall /> 

                </div>

                {/* action */}
                <WebbDividerMedium />
                <div className={`p-3 ${commerce ? 'd-none' : 'd-none'}`}>
                  
                  <div className='mb-2'></div>
                  <button className='btn btn-outline-secondary border text-small rounded-xx text-nowrap w-100' 
                    
                    onClick={() => modalTransactionClose() }
                  >
                    {'Cancel'}
                  </button>
                </div>


                {/* footer */}
                <WebbDividerSmall />

              </div>
            </div>
          </div>
        </div>
      </div> 

      
      {/* modal - minter */}
      <div className={`container ${modalMinterStatus ? '' : 'd-none'}`}>
        <div className='row'>
          <div className='col'>
            <div className="modal-overlay" >
              <div className="modal-content rounded-xd"  onClick={(e) => e.stopPropagation()}>
                {/* modal header */}
                <div className=""></div>

                {/* modal content */}
                <div className='modal-body border-none p-3'>
                  <div className='text-center'>
                    <p className={`text-normal m-0`}>{'Mint Asset'}</p>
                  </div>

                  <WebbDividerSmall />  
                  <div className='media-banner'>
                    <img className='rounded-xd' src={data?.media?.link || '******'} alt='...'></img>
                  </div>

                  <WebbDividerSmall />
                  <div className={`text-center`}>
                    <p className='text-small m-0 d-none'>{''}</p>
                    <p className='text-lead text-bold m-0'>{data?.meta?.name || '******'}</p>
                    <p className='m-0'>ID: {data?.webx?.number || '******'}</p>
                  </div>

                  {/* <div className={`text-center ${mintstart && minterstatus ? 'd-none' : ''}`}>
                    <WebbDividerMedium />
                    <label className="form-label text-small">Enter Your Minter Account Passcode</label>
                    <input type="text" 
                      className="form-control height-md text-center"
                      style={{fontSize: '0.9rem', height: '2.7rem'}}
                      pattern="[0-9][0-9]*"
                      value={secret}
                      onChange={({ target }) => {{ 
                        setSecret(target.validity.valid 
                          ? target.value : secret ); 
                      };setMemo('') }}
                      disabled={minterstatus}
                      placeholder={''} 
                    />
                    <p className={`mt-1 mb-0 text-color-error text-small ${mintstart  && minterstatus == false? '' : 'd-none'}`}>{memo}</p>
                  </div> */}

                </div>

                {/* <p className={mintstart ? "text-center text-small m-0" :'d-none'}>{memo}</p> */}

                {/* action minter */}
                <div className={`p-3 ${minterstatus && mintstart && !mintdone ? '' : 'd-none'}`}>
                  
                  <WebbDividerMedium/>
                  <p className="text-center text-small m-0">{memo}</p>
                  <div className='mb-2'></div>
                  <p className="text-center text-color-error m-0">{'Do not close the window'}</p>

                  <WebbDividerSmall />
                  <div className="progress" role="progressbar" aria-label="..." 
                    aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" 
                    style={{width: "100%", height: '0.5rem'}}>
                    <div className="progress-bar progress-bar-striped progress-bar-animated" 
                      style={{width: "100%", height: '0.5rem'}}
                    ></div>
                  </div>
                  
                </div>
                <div className={`p-3 ${mintstart && mintdone ? '' : 'd-none'}`}>
                  <p className="text-center text-bold m-0">{'Congratulations'}</p>
                  <WebbDividerSmall />

                  <Lottie  options={defaultOptions} height={200} width={200} />
                  <p className="text-center m-0">{'Mint Successful'}</p>
                </div>

                <div className={`p-3 ${mintstart && minterstatus ? 'd-none' : ''}`}>

                  <button className='btn btn-primary text-small rounded-xx text-nowrap w-100' 
                    disabled = { submit || done }
                    onClick={()=>{handleUnitTransfer() }}
                  >
                    {'Mint Now'}
                  </button>
                  <div className='mb-2'></div>
                  <button className='btn btn-outline-secondary border text-small rounded-xx text-nowrap w-100' 
                    onClick={() => {modalMinterClose(); navigate("/user/assets")} }
                  >
                    {'Skip'}
                  </button>                  
                  <WebbDividerSmall />
                </div>

                {/* modal footer */}
                {/* <div className="text-center text-small text-primary ">{memo}</div>                 */}


              </div>
            </div>
          </div>
        </div>
      </div>

      {/* modal-edit offers */}
      
                <div
                  className="modal fade"
                  id="modEditOffer"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog rounded-xd">
                    <div className="modal-content p-0 m-0 rounded-xd w-100 w-100">
                      <div className="modal-header border-none">
                        <p className="text-normal align-middle m-0 p-0">Edit</p>
                        <button
                          type="button"
                          className="btn-close text-small"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>

                      {/* modal content */}
                      <div className="modal-body border-none">
                        <div className="mb-3">
                          <div>
                          <label className="form-label text-small">
                            Rate <FormNeeded />
                          </label>
                          <div className='d-flex gap-3'>
                          <input
                            type="text"
                            className="form-control height-md"
                            style={{ fontSize: "0.9rem", height: "2.7rem" }}
                            value={offerRate}
                            onChange={({ target }) => {
                              setOfferRate(target.value);
                            }}
                          ></input>
                           
                          <button
                            className="btn btn-primary btn-sm text-small rounded-xx"
                            onClick={()=>{handleChangeRate(itemIdOffer)}}
                            data-bs-dismiss="modal"
                          >
                            Save
                          </button>
                          </div>
                          </div>
                          <div className='d-flex justify-content-between mt-4'>
                          
                          <p className='m-0'> Cancel Offer <FormNeeded /></p>
                          <button
                            className="btn btn-danger btn-sm text-small rounded-xx"
                            onClick={()=>{handleCancelOffer(itemIdOffer)}}
                            data-bs-dismiss="modal"
                            style={{width:"87px"}}
                          >
                            Cancel
                          </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
    </>
  )
}

