// assets
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { NumberFormat } from '../../services/srvc-utilities'

import WebbDividerSmall from '../webx/webb-divider-sm'
import WebbDividerMedium from '../webx/webb-divider-md'

import { GetUserForm } from '../../services/srvc-utilities'
import { GetLocalUser, GetLocalBusiness } from '../../services/srvc-auth-local'

import AssetActionsModule from './assets-actions'

import { CredentialsDetails, CredentialsListTrxn } from '../../services/srvc-credentials-realm'
import { ScoresUserDetails } from '../../services/srvc-users-scores'

import { AccountsMinterDetails } from '../../services/srvc-accounts-minter-realm'

import listStatus from '../../data.static/data-transfers-status.json'
import AssetActionsModuleXX from './assets-actions-xx'

export default function AssetDetailsUserModule (props) {
  

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx

  const navigate = useNavigate()
  const {id} = useParams()

  const [loader, setLoader] = useState(true)
  
  const [list, setList] = useState(listStatus.data)
  const [data, setData] = useState({})
  const [trxnlist,setTrxnList] = useState([])
  const [webxNumber,setWebxNumber] = useState()

  const [balance, setBalance] = useState({number: '0', ticker: ''})
  const [member, setMember] = useState(false)

  const [refresh, setRefresh] = useState(false)
  

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true)

        const result = await CredentialsDetails({
          data: { item: id, user: asset.item },
          srvc: '******'
        })
        //console.log(result)

        if (result.stat) {
        setData(result?.data)
        setWebxNumber(result?.data?.webx.nmbr)
        }

        if (result.stat) {
          if (result?.data?.taxxon == "315c237f1c84475b8881de209b39a9664") {
            
            // set credential is membership
            setMember(true)

            // member score balance
            const balance = await ScoresUserDetails({
              data: {user: asset.item, asset: result.data.asset.item}, 
              srvc: '******'
            })
            // console.log (balance)
            if (balance.stat) {
              setBalance({number: balance.data.balance.number/1000000, ticker: ''})
            }
          }
        }

        setLoader(false)
      }
      fetchData()
    } else {}
  }, [refresh])

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true)

        const result = await CredentialsListTrxn({
          data: { number: webxNumber },
          srvc: '******'
        })

        // console.log(result)
        if (result.stat) setTrxnList(result?.data?.list)


        setLoader(false)
      }
      if(webxNumber){
        fetchData()
      }
    } else {}
  }, [webxNumber])


  const handleRefresh = async() => {
    setRefresh(!refresh)
  }


  if (loader) return <></>

  return (
    <>
      
      {/* data */}
      <div className=''>
        <div className='media-standard rounded-xd'>
          <img className='w-100 rounded-xd shadow' src = {data?.media?.link} alt="..."></img>
        </div>
        
        <div className={`rounded-none mx-3 back-color-${data.status.mint && !data.status.burn ? 'success' : 'error'}`}>
          <p className='text-uppercase text-small m-0 text-center text-color-wite p-2'>
            {data.status.mint && !data.status.burn ? 'active' : 'inactive'}
          </p>
        </div>
      </div>

      
      <div className='back-color-wite p-3 rounded-xd'>
        <p className='text-normal text-bold m-0 text-truncate'>{data.meta.name}</p>
        <p className='text-small m-0 mb-2'>{data.webx.nmbr}</p>
        
        <p className='m-0 text-wd'>{data.meta.memo}</p>
        <WebbDividerSmall />
        <div className=''>

          <span className='text-small m-0'>{data.creator.name}</span>

        </div>
        <div className=''>

        <span className='text-small m-0'>Created on : </span>
        <span className='text-small'>{(new Date(parseInt(data?.created))).toLocaleString() || '******'}</span>
 
        </div>
      </div>

      {/* actions */}
      <div className='mb-1'></div>
      <div className=''>
        {/* <AssetActionsModule data={data}/> */}
        <AssetActionsModuleXX data={data} handleRefresh = {handleRefresh} />
      </div>

      {/* buy/sale lists */}
         <div className='back-color-wite border rounded my-4'>
         {/* header */}
         <div className="border-bottom text-small text-bold">
           <div className="d-flex p-2 px-3">
   
             <div className="" style={{width: '5%'}}>         
               <p className="m-0">
                 <span className="text-small">
                   <i className="bx bxs-grid-alt text-color-tint"></i>
                 </span>
               </p>
             </div>  
   
             <div className="" style={{minWidth: '45%'}}>
               <p className="m-0">
                 <span className="">{'User'}</span>
               </p>
             </div>    
   
             <div className="" style={{width: '30%'}}>         
               <p className="m-0 text-sm">
                 <span className="">{'Date'}</span>
               </p>
             </div>  
    
             <div className="ms-auto text-end text-sm" style={{width: '20%'}}>         
               <p className="m-0 text-sm ">
                 <span className="">{'Status'}</span>
               </p>
             </div>
   
         </div>
         </div>
   
         {/* data */}
         {trxnlist && trxnlist.map((item, i) => (
         <div key={i}>
           <div className="d-flex p-2 px-3">
           
             <div className="" style={{width: '5%'}}>         
               <p className="m-0">
                 <span className="text">
                   <i className="bx bxs-image text-color-tint"></i>
                 </span>
               </p>
               
             </div>  
   
             <div className="" style={{width: '45%'}}>
               <p className="m-0">
                 <span className="text-bold">{item.user.name}</span>
               </p>
             </div>
             <div className="" style={{width: '30%'}}>
             <p className="m-0">
             <span>{(new Date(parseInt(item?.crts))).toLocaleString() || '******'}</span>
               </p>
             </div>
             <div className="ms-auto text-end" style={{width: '20%'}}>
             <p className="m-0">
                 <span className="text-bold">{item.actv?"Active":"Inactive"}</span>
               </p>
             </div>
           </div>
           <div className={i < data.length-1 ? 'border-bottom': ''}></div>
         </div>
         ))}
   
         </div>

      {/* membership */}
      <WebbDividerSmall />
      <div className={member ? 'p-3 back-color-wite rounded-xd border' : 'd-none'}>
        <div className='d-flex text-normal'>
          <div className='me-auto'>
            <span>Membership Points</span>
          </div>  
          <div className='text-end'>
            <span>{balance?.number}</span>
            <span>{' '}</span>
            <span><i className='bx bx-star'></i></span>
          </div>  
        </div>  
        <div className='mb-2'></div>
        <div className=''>
          <span>Leaderboard</span>
        </div>
      </div>


      {/* features and data */}

    </>
  )
}
